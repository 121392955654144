import './src/styles/global.css';
import React from 'react';
import { pdfjs } from 'react-pdf';
import AppProviders from './src/components/app-providers';

export const wrapRootElement = ({ element }) => {
  <AppProviders>{element}</AppProviders>;
};

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();
