import fetch from 'isomorphic-fetch'
import {
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

const API_BASE = process.env.API_BASE || 'http://localhost:8080'

const httpLink = new HttpLink({
  uri: `${API_BASE}/graphql`,
  credentials: 'include',
  fetch,
})

const uploadLink = createUploadLink({
  uri: `${API_BASE}/graphql`,
  credentials: 'include',
  fetch,
})

const client = new ApolloClient({
  link: ApolloLink.from([uploadLink, httpLink]),
  cache: new InMemoryCache(),
})

export default client
